.App {
  padding: 16px;
  /* min-height: 50vh; */
  box-sizing: border-box;
  background-color: #eeeeee;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.demo-container {
  display: flex;
  flex-direction: column;
}

.demo-container img {
  max-width: 100%;
}

.demo-pictures,
.demo-shapes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #cccccc;
  margin: 8px;
  min-height: 76px;
}

.demo-pictures img,
.demo-shapes img {
  margin: 8px;
  opacity: 0.4;
  cursor: pointer;
}

.demo-pictures img {
  height: 80px;
  max-width: 160px;
  opacity: 0.6;
}
.demo-shapes img {
  height: 60px;
  max-width: 120px;
  opacity: 0.6;
}

.demo-pictures img:hover,
.demo-shapes img:hover {
  opacity: 1;
  transform: scale(1.1);
}

.demo-pictures img.demo-picture-selected,
.demo-shapes img.demo-shape-selected {
  opacity: 1;
}

.demo-editor {
  background-color: #cccccc;
  margin: 8px;
  min-height: 30vh;
  position: relative;
}

.demo-preview {
  /* background-color: black; */
  margin: 8px;
  min-height: 30vh;
  position: relative;
  text-align: center;
}
